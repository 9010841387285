import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 62.1875 63.19999999999982"
          width="62.1875"
          height="63.19999999999982">
          <g transform="translate(10 10) rotate(0 21.09375 21.59999999999991)">
            <text
              x="30"
              y="45"
              fontFamily="Cascadia, Segoe UI Emoji"
              fontSize="40px"
              fill="#fff"
              textAnchor="middle"
              direction="ltr"
              dominantBaseline="alphabetic">
              JA
            </text>
          </g>
        </svg>
      </g>
    </g>
  </svg>
);

export default IconLogo;
